import React, { useState, useEffect } from 'react';
import './App.css';
import { Container, Table, Row, Figure } from 'react-bootstrap';
import moment from 'moment-timezone'; // Importar moment.js para formatear fechas


function App() {
  const [embajadores, setEmbajadores] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(""); // Nuevo estado para la última actualización de los datos

  useEffect(() => {
    fetchEmbajadores();
  }, []);

  const fetchEmbajadores = async () => {
    try {
      const response = await fetch('https://analiticastolivbackend.eastus.cloudapp.azure.com/embajadores/theoffice');
      const data = await response.json();
      const latestUpdate = data.reduce((latest, embajador) => embajador.timestamp > latest ? embajador.timestamp : latest, "");
      setEmbajadores(data);
      setLastUpdate(latestUpdate); // Actualiza el estado de lastUpdate con la fecha más reciente
    } catch (error) {
      console.error('Error fetching embajadores:', error);
    }
  };

  return (
    <Container>
      <Row md="auto"><h1>Embajadores "The Office"</h1></Row>
      <Row>
      <Figure>
        <Figure.Image
          width={100}
          height={180}
          alt="171x180"
          src="logo-toliv-marker.svg"
        />
        <Figure.Caption>
          Actualizado {moment(lastUpdate).format('DD/MM/YYYY HH:mm')} {/* Muestra la última fecha de actualización formateada */}
        </Figure.Caption>
      </Figure>
      </Row>
      
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Código cupón</th>
            <th>Cantidad venta</th>
          </tr>
        </thead>
        <tbody>
          {embajadores
            .sort((a, b) => b.embajador_cantidad_vendida - a.embajador_cantidad_vendida)
            .map((embajador, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{embajador.embajador_nombre_cupon}</td>
                <td>{embajador.embajador_cantidad_vendida}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default App;
